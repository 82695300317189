exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".sound-editor-not-supported_container_2WslL {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    width: 100%;\n    height: 100%;\n    font-size: 1.5em;\n}\n", ""]);

// exports
exports.locals = {
	"container": "sound-editor-not-supported_container_2WslL"
};