exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.cloud-variable-badge_badge_2kZVK {\n    padding: 0.5rem;\n    margin: 5px 0 8px 0;\n    border: 1px solid var(--badge-border);\n    background-color: var(--badge-background);\n    display: flex;\n    flex-direction: column;\n    gap: 0.5rem;\n    border-radius: 0.5rem;\n}\n\n.cloud-variable-badge_title_2xJoL {\n    display: flex;\n    align-items: center;\n    gap: 0.5rem;\n    font-weight: bold;\n}\n\n.cloud-variable-badge_cloud-icon_1JP1e {\n    filter: var(--filter-icon-gray);\n}\n\n.cloud-variable-badge_servers_1fLAL {\n    display: flex;\n    gap: 0.5rem;\n    align-items: center;\n}\n\n.cloud-variable-badge_server_3s9y9 {\n    border: 1px solid var(--ui-black-transparent);\n    padding: 0.25rem 0.5rem;\n    margin: 0;\n    background: none;\n    border-radius: 1rem;\n}\n\n.cloud-variable-badge_server_3s9y9.cloud-variable-badge_selected_3ZyWW {\n    background-color: var(--looks-secondary);\n    color: white;\n}\n", ""]);

// exports
exports.locals = {
	"badge": "cloud-variable-badge_badge_2kZVK",
	"title": "cloud-variable-badge_title_2xJoL",
	"cloud-icon": "cloud-variable-badge_cloud-icon_1JP1e",
	"cloudIcon": "cloud-variable-badge_cloud-icon_1JP1e",
	"servers": "cloud-variable-badge_servers_1fLAL",
	"server": "cloud-variable-badge_server_3s9y9",
	"selected": "cloud-variable-badge_selected_3ZyWW"
};