exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.security-manager-modal_modal-content_3qWwH {\n    width: 440px;\n    margin-top: 25px;\n}\n\n.security-manager-modal_body_Pn7qy {\n    background: var(--ui-white);\n    padding: 1rem 1.5rem;\n}\n\n.security-manager-modal_body_Pn7qy p {\n    margin: 8px 0;\n}\n\n.security-manager-modal_buttons_1LSKA {\n    display: flex;\n}\n\n.security-manager-modal_buttons_1LSKA :disabled {\n    opacity: 0.8;\n}\n\n.security-manager-modal_deny-button_3Vd-R, .security-manager-modal_allow-button_3tcXk {\n    flex-grow: 1;\n    font: inherit;\n    color: inherit;\n    padding: 0.75rem 1rem;\n    border-radius: 0.25rem;\n    border: 1px solid var(--ui-black-transparent);\n    font-weight: 600;\n    font-size: 0.85rem;\n}\n\n.security-manager-modal_deny-button_3Vd-R {\n    background-color: rgb(255, 92, 92);\n    color: white;\n    margin-right: 8px;\n}\n\n.security-manager-modal_allow-button_3tcXk {\n    background-color: #24cd11;\n    color: black;\n}\n", ""]);

// exports
exports.locals = {
	"modal-content": "security-manager-modal_modal-content_3qWwH",
	"modalContent": "security-manager-modal_modal-content_3qWwH",
	"body": "security-manager-modal_body_Pn7qy",
	"buttons": "security-manager-modal_buttons_1LSKA",
	"deny-button": "security-manager-modal_deny-button_3Vd-R",
	"denyButton": "security-manager-modal_deny-button_3Vd-R",
	"allow-button": "security-manager-modal_allow-button_3tcXk",
	"allowButton": "security-manager-modal_allow-button_3tcXk"
};