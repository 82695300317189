exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n\n/* layout contants from `layout-constants.js` */\n\n.invalid-project-modal_modal-content_AZ4Xt {\n    max-width: 480px;\n    margin-top: 50px;\n}\n\n.invalid-project-modal_body_3FIpH {\n    background: var(--ui-modal-background);\n    padding: 1.5rem 2.25rem;\n    max-height: calc(100vh - 150px);\n    overflow: auto;\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n}\n\n.invalid-project-modal_error_ykzaO {\n    font-family: monospace;\n    height: 125px;\n    width: 100%;\n    box-sizing: border-box;\n    resize: vertical;\n    padding: 0.5rem;\n    border-radius: 0.5rem;\n    border: 1px solid var(--ui-black-transparent);\n    background-color: var(--input-background);\n    color: inherit;\n}\n\n.invalid-project-modal_button_1oppw {\n    font: inherit;\n    font-weight: bold;\n    padding: 0.75rem 1rem;\n    border-radius: 0.25rem;\n    border: 1px solid var(--ui-black-transparent);\n    background-color: var(--looks-secondary);\n    color: white;\n}\n", ""]);

// exports
exports.locals = {
	"modal-content": "invalid-project-modal_modal-content_AZ4Xt",
	"modalContent": "invalid-project-modal_modal-content_AZ4Xt",
	"body": "invalid-project-modal_body_3FIpH",
	"error": "invalid-project-modal_error_ykzaO",
	"button": "invalid-project-modal_button_1oppw"
};