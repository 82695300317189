exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n\n/* layout contants from `layout-constants.js` */\n\n.selector_wrapper_8_BHs {\n    width: 150px;\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    background: var(--ui-tertiary);\n}\n\n.selector_new-buttons_2qHDd {\n    position: absolute;\n    bottom: 0;\n    width: 100%;\n\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: space-around;\n    padding: 0.75rem 0;\n    color: var(--looks-secondary);\n    text-align: center;\n    background: none;\n}\n\n.selector_new-buttons_2qHDd:before {\n    content: \"\";\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    right:0;\n    width: 100%;\n    pointer-events: none;\n}\n\n.selector_new-buttons_2qHDd > button + button {\n    margin-top: 0.75rem;\n}\n\n.selector_list-area_1Xbj_ {\n    /* Must have some height (recalculated by flex-grow) in order to scroll */\n    height: 0;\n    flex-grow: 1;\n    overflow-y: auto;\n    overflow-x: hidden;\n    display: flex;\n    flex-direction: column;\n}\n\n.selector_list-area_1Xbj_:after {\n    /* Make sure there is room to scroll beyond the last tile */\n    content: '';\n    display: block;\n    height: 70px;\n    width: 100%;\n    flex-shrink: 0;\n    order: 99999999;\n}\n\n.selector_list-item_3N_u7 {\n    width: 5rem;\n    height: 5rem;\n    margin: 0.5rem auto;\n}\n\n@media only screen and (max-width: 1249px) {\n    .selector_wrapper_8_BHs {\n        width: 80px;\n    }\n\n    .selector_list-item_3N_u7 {\n        width: 4rem;\n    }\n}\n\n.selector_list-item_3N_u7.selector_placeholder_2pqWG {\n    background: white;\n    filter: opacity(15%) brightness(0%);\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "selector_wrapper_8_BHs",
	"new-buttons": "selector_new-buttons_2qHDd",
	"newButtons": "selector_new-buttons_2qHDd",
	"list-area": "selector_list-area_1Xbj_",
	"listArea": "selector_list-area_1Xbj_",
	"list-item": "selector_list-item_3N_u7",
	"listItem": "selector_list-item_3N_u7",
	"placeholder": "selector_placeholder_2pqWG"
};