exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.meter_green_oUPv2 {\n    fill: rgb(171, 220, 170);\n    stroke: rgb(174, 211, 168);\n}\n\n.meter_yellow_3uz-j {\n    fill: rgb(251, 219, 130);\n    stroke: rgb(239, 212, 134);\n}\n\n.meter_red_1aMUm {\n    fill: rgb(251, 194, 142);\n    stroke: rgb(235, 189, 142);\n}\n\n.meter_mask-container_11m_n {\n    position: relative;\n}\n\n.meter_mask_B-cd7 {\n    position: absolute;\n    top: 0;\n    left: 0;\n    height: 100%;\n    width: 100%;\n    transform-origin: top;\n    will-change: transform;\n    background: var(--ui-primary);\n    opacity: 0.75;\n}\n", ""]);

// exports
exports.locals = {
	"green": "meter_green_oUPv2",
	"yellow": "meter_yellow_3uz-j",
	"red": "meter_red_1aMUm",
	"mask-container": "meter_mask-container_11m_n",
	"maskContainer": "meter_mask-container_11m_n",
	"mask": "meter_mask_B-cd7"
};