exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.settings-menu_icon_3QaRk {\n    width: 1.5rem;\n}\n\n.settings-menu_theme-label_nv_1M {\n    flex: 1;\n}\n\n.settings-menu_option_3rMur {\n    display: flex;\n    align-items: center;\n    gap: .5rem;\n}\n\n.settings-menu_check_3ssaq {\n    margin: 0 .25rem 0 0;\n    visibility: hidden;\n}\n\n[dir=\"rtl\"] .settings-menu_check_3ssaq {\n    margin: 0 0 0 .25rem;\n}\n\n.settings-menu_selected_2pjxx {\n    visibility: visible;\n}\n\n.settings-menu_submenu-label_r-gA3 {\n    flex: 1;\n}\n\n.settings-menu_dropdown-label_3f68c {\n    margin: 0 0.5rem 0 0.25rem;\n}\n\n[dir=\"rtl\"] .settings-menu_dropdown-label_3f68c {\n    margin: 0 0.25rem 0 0.5rem;\n}\n\n.settings-menu_expand-caret_u2sS- {\n    transform: rotate(-90deg);\n}\n\n[dir=\"rtl\"] .settings-menu_expand-caret_u2sS- {\n    transform: rotate(90deg);\n}\n\n/* Extra padding to deal with scrollbar overlapping options in Firefox */\n\n[dir=\"ltr\"] .settings-menu_language-menu-item_3M-8n {\n    padding-right: 1rem;\n}\n\n[dir=\"rtl\"] .settings-menu_language-menu-item_3M-8n {\n    padding-left: 1rem;\n}\n\n.settings-menu_language-submenu_2LYn6 > ul {\n    max-height: calc(100vh - 5rem); /* Fallback if dvh not supported */\n    max-height: calc(100dvh - 5rem);\n}\n\n@media only screen and (max-width: 1124px) {\n    .settings-menu_dropdown-label_3f68c {\n        display: none;\n    }\n}\n\n.settings-menu_accent-icon-outer_3Yb2D {\n    width: 24px;\n    height: 24px;\n    border: 3px solid var(--menu-bar-foreground);\n    border-radius: 100%;\n    box-sizing: border-box;\n    transform-origin: center;\n    transform: scale(90%);\n}\n\n.settings-menu_disabled_1wLGe {\n    opacity: 0.5;\n}\n\n.settings-menu_disabled_1wLGe img {\n    filter: grayscale(100%);\n}\n\n.settings-menu_open-link_2hrY- {\n    margin-left: 0.5rem;\n}\n", ""]);

// exports
exports.locals = {
	"icon": "settings-menu_icon_3QaRk",
	"theme-label": "settings-menu_theme-label_nv_1M",
	"themeLabel": "settings-menu_theme-label_nv_1M",
	"option": "settings-menu_option_3rMur",
	"check": "settings-menu_check_3ssaq",
	"selected": "settings-menu_selected_2pjxx",
	"submenu-label": "settings-menu_submenu-label_r-gA3",
	"submenuLabel": "settings-menu_submenu-label_r-gA3",
	"dropdown-label": "settings-menu_dropdown-label_3f68c",
	"dropdownLabel": "settings-menu_dropdown-label_3f68c",
	"expand-caret": "settings-menu_expand-caret_u2sS-",
	"expandCaret": "settings-menu_expand-caret_u2sS-",
	"language-menu-item": "settings-menu_language-menu-item_3M-8n",
	"languageMenuItem": "settings-menu_language-menu-item_3M-8n",
	"language-submenu": "settings-menu_language-submenu_2LYn6",
	"languageSubmenu": "settings-menu_language-submenu_2LYn6",
	"accent-icon-outer": "settings-menu_accent-icon-outer_3Yb2D",
	"accentIconOuter": "settings-menu_accent-icon-outer_3Yb2D",
	"disabled": "settings-menu_disabled_1wLGe",
	"open-link": "settings-menu_open-link_2hrY-",
	"openLink": "settings-menu_open-link_2hrY-"
};