exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n\n/* layout contants from `layout-constants.js` */\n\n.tag-button_tag-button_nBLRM {\n    padding: .625rem 1rem;\n    background: var(--looks-secondary);\n    border-radius: 1.375rem;\n    color: white;\n    height: 2.5rem;\n}\n\n.tag-button_tag-button-icon_26hvC {\n    max-width: 1rem;\n    max-height: 1rem;\n}\n\n.tag-button_active_2stEz {\n    background: var(--data-primary);\n}\n", ""]);

// exports
exports.locals = {
	"tag-button": "tag-button_tag-button_nBLRM",
	"tagButton": "tag-button_tag-button_nBLRM",
	"tag-button-icon": "tag-button_tag-button-icon_26hvC",
	"tagButtonIcon": "tag-button_tag-button-icon_26hvC",
	"active": "tag-button_active_2stEz"
};