exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".url_url_3Y61f {\n    font-family: monospace;\n    -webkit-user-select: text;\n       -moz-user-select: text;\n        -ms-user-select: text;\n            user-select: text;\n    word-wrap: break-word;\n}\n.url_url_3Y61f::before {\n    content: '\"';\n}\n.url_url_3Y61f::after {\n    content: '\"';\n}\n", ""]);

// exports
exports.locals = {
	"url": "url_url_3Y61f"
};