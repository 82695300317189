exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.progress-ring_progress-ring_P_His {\n    margin: 0.5rem;\n}\n\n.progress-ring_progress-ring-svg_17Srp {\n    fill: transparent;\n}\n\n.progress-ring_progress-ring_P_His .progress-ring_progress-ring-ring_2NyUq {\n    stroke: var(--looks-light-transparent);\n}\n\n.progress-ring_progress-ring_P_His .progress-ring_progress-ring-value_1SVN5 {\n    stroke: var(--looks-secondary);\n    stroke-linecap: round;\n}\n", ""]);

// exports
exports.locals = {
	"progress-ring": "progress-ring_progress-ring_P_His",
	"progressRing": "progress-ring_progress-ring_P_His",
	"progress-ring-svg": "progress-ring_progress-ring-svg_17Srp",
	"progressRingSvg": "progress-ring_progress-ring-svg_17Srp",
	"progress-ring-ring": "progress-ring_progress-ring-ring_2NyUq",
	"progressRingRing": "progress-ring_progress-ring-ring_2NyUq",
	"progress-ring-value": "progress-ring_progress-ring-value_1SVN5",
	"progressRingValue": "progress-ring_progress-ring-value_1SVN5"
};