exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.file-input_container_1c4jE {\n    display: block;\n    position: relative;\n    padding: 1rem;\n    margin: 1rem 0;\n    text-align: center;\n    border: 4px dashed var(--ui-tertiary);\n    border-radius: 5px;\n    cursor: pointer;\n    background: none;\n    width: 100%;\n}\n\n.file-input_container_1c4jE:focus-within {\n    border-color: var(--looks-secondary);\n}\n", ""]);

// exports
exports.locals = {
	"container": "file-input_container_1c4jE"
};