exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.green-flag_green-flag_1kiAo {\n    width: 2rem;\n    height: 2rem;\n    padding: 0.375rem;\n    border-radius: 0.25rem;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n    user-drag: none;\n    cursor: pointer;\n}\n\n.green-flag_green-flag_1kiAo:hover {\n    background-color: var(--looks-light-transparent);\n}\n\n.green-flag_green-flag_1kiAo.green-flag_is-active_2oExT {\n    background-color: var(--looks-transparent);\n}\n", ""]);

// exports
exports.locals = {
	"green-flag": "green-flag_green-flag_1kiAo",
	"greenFlag": "green-flag_green-flag_1kiAo",
	"is-active": "green-flag_is-active_2oExT",
	"isActive": "green-flag_is-active_2oExT"
};