exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.username-modal_modal-content_2pKC4 {\n    width: 400px;\n}\n\n.username-modal_body_UaL6e {\n    background: var(--ui-modal-background);\n    padding: 1.5rem 2.25rem;\n}\n\n.username-modal_body_UaL6e input[type=\"checkbox\"],\n.username-modal_body_UaL6e input[type=\"radio\"] {\n    margin: 3px;\n}\n\n.username-modal_text-input_3z1ni {\n    margin-bottom: 1rem;\n    width: 100%;\n    border: 1px solid var(--ui-black-transparent);\n    border-radius: 5px;\n    padding: 0 1rem;\n    height: 3rem;\n    color: var(--ui-text-primary-transparent);\n    font-size: .875rem;\n    background-color: var(--input-background);\n}\n\n.username-modal_button-row_2amuh {\n    font-weight: bolder;\n    text-align: right;\n}\n\n.username-modal_button-row_2amuh button {\n    padding: 0.75rem 1rem;\n    border-radius: 0.25rem;\n    background: white;\n    border: 1px solid var(--ui-black-transparent);\n    font-weight: 600;\n    font-size: 0.85rem;\n}\n\n.username-modal_button-row_2amuh button[disabled] {\n    opacity: 0.5;\n}\n\n[dir=\"ltr\"] .username-modal_button-row_2amuh button + button {\n    margin-left: 0.5rem;\n}\n\n[dir=\"rtl\"] .username-modal_button-row_2amuh button + button {\n    margin-right: 0.5rem;\n}\n\n.username-modal_button-row_2amuh button.username-modal_ok-button_UEZfz {\n    background: var(--looks-secondary);\n    border: var(--looks-secondary);\n    color: white;\n}\n\n.username-modal_button-row_2amuh button.username-modal_cancel-button_3bs7j {\n    color: black;\n}\n\n.username-modal_help-text_3dN2- {\n    margin-bottom: 1rem;\n}\n\n.username-modal_must-change_1uhS8 {\n    font-weight: bold;\n}\n\n.username-modal_reset-link_2djuz {\n    cursor: pointer;\n}\n", ""]);

// exports
exports.locals = {
	"modal-content": "username-modal_modal-content_2pKC4",
	"modalContent": "username-modal_modal-content_2pKC4",
	"body": "username-modal_body_UaL6e",
	"text-input": "username-modal_text-input_3z1ni",
	"textInput": "username-modal_text-input_3z1ni",
	"button-row": "username-modal_button-row_2amuh",
	"buttonRow": "username-modal_button-row_2amuh",
	"ok-button": "username-modal_ok-button_UEZfz",
	"okButton": "username-modal_ok-button_UEZfz",
	"cancel-button": "username-modal_cancel-button_3bs7j",
	"cancelButton": "username-modal_cancel-button_3bs7j",
	"help-text": "username-modal_help-text_3dN2-",
	"helpText": "username-modal_help-text_3dN2-",
	"must-change": "username-modal_must-change_1uhS8",
	"mustChange": "username-modal_must-change_1uhS8",
	"reset-link": "username-modal_reset-link_2djuz",
	"resetLink": "username-modal_reset-link_2djuz"
};