exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.divider_divider_1_Adi {\n    border-right: 1px dashed var(--ui-black-transparent);\n}\n", ""]);

// exports
exports.locals = {
	"divider": "divider_divider_1_Adi"
};