exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n\n/* layout contants from `layout-constants.js` */\n\n.inline-message_inline-message_2feS3 {\n    color: var(--menu-bar-foreground);\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    font-size: .8125rem;\n    padding: 0 0.5rem;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n}\n\n.inline-message_success_1jfE0 {\n    color: var(--ui-white-dim);\n}\n\n.inline-message_info_E7dNO {\n    color: var(--menu-bar-foreground);\n}\n\n.inline-message_warn_3DS5G {\n    color: var(--error-light);\n}\n\n.inline-message_spinner_1vybW {\n    margin-right: 0.5rem;\n}\n", ""]);

// exports
exports.locals = {
	"inline-message": "inline-message_inline-message_2feS3",
	"inlineMessage": "inline-message_inline-message_2feS3",
	"success": "inline-message_success_1jfE0",
	"info": "inline-message_info_E7dNO",
	"warn": "inline-message_warn_3DS5G",
	"spinner": "inline-message_spinner_1vybW"
};