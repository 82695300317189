exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.dial_container_3hox- {\n    padding: 1rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n}\n\n.dial_dial-container_ktOnA {\n    position: relative;\n}\n\n.dial_dial-face_3NuSA, .dial_dial-handle_1tfZT, .dial_gauge_3g5NZ {\n    position: absolute;\n    top: 0;\n    left: 0;\n    overflow: visible;\n}\n\n.dial_dial-face_3NuSA {\n    width: 100%;\n}\n\n.dial_dial-handle_1tfZT {\n    cursor: pointer;\n    width: 40px;\n    height: 40px;\n    /* Use margin to make positioning via top/left easier */\n    margin-left: calc(40px / -2);\n    margin-top: calc(40px / -2);\n}\n\n.dial_gauge-path_1WoJ6 {\n    fill: var(--looks-transparent);\n    stroke: var(--looks-secondary);\n    stroke-width: 1px;\n}\n", ""]);

// exports
exports.locals = {
	"container": "dial_container_3hox-",
	"dial-container": "dial_dial-container_ktOnA",
	"dialContainer": "dial_dial-container_ktOnA",
	"dial-face": "dial_dial-face_3NuSA",
	"dialFace": "dial_dial-face_3NuSA",
	"dial-handle": "dial_dial-handle_1tfZT",
	"dialHandle": "dial_dial-handle_1tfZT",
	"gauge": "dial_gauge_3g5NZ",
	"gauge-path": "dial_gauge-path_1WoJ6",
	"gaugePath": "dial_gauge-path_1WoJ6"
};