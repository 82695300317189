exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.stop-all_stop-all_1Y8P9 {\n    width: 2rem;\n    height: 2rem;\n    padding: 0.375rem;\n    border-radius: 0.25rem;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n    cursor: pointer;\n}\n\n.stop-all_stop-all_1Y8P9:hover {\n    background-color: var(--looks-light-transparent);\n}\n\n.stop-all_stop-all_1Y8P9 {\n    opacity: 0.5;\n}\n\n.stop-all_stop-all_1Y8P9.stop-all_is-active_3nog0 {\n    opacity: 1;\n}\n", ""]);

// exports
exports.locals = {
	"stop-all": "stop-all_stop-all_1Y8P9",
	"stopAll": "stop-all_stop-all_1Y8P9",
	"is-active": "stop-all_is-active_3nog0",
	"isActive": "stop-all_is-active_3nog0"
};