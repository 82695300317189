exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.waveform_container_2K9Om {\n    width: 100%;\n}\n\n.waveform_waveform-path_TskyB {\n    /*\n        This color is lighter than sound-primary, but\n        cannot use alpha because of overlapping elements.\n    */\n    fill: hsl(300, 54%, 72%);\n    stroke: var(--sound-tertiary);\n}\n\n.waveform_baseline_2J5dw {\n    stroke: var(--sound-tertiary);\n}\n", ""]);

// exports
exports.locals = {
	"container": "waveform_container_2K9Om",
	"waveform-path": "waveform_waveform-path_TskyB",
	"waveformPath": "waveform_waveform-path_TskyB",
	"baseline": "waveform_baseline_2J5dw"
};