exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n\n/* layout contants from `layout-constants.js` */\n\n/* overridden by src/lib/themes/guiHelpers.js */\n\n/*\n    Contains constants for the z-index values of elements that are part of the global stack context.\n    In other words, z-index values that are \"inside\" a component are not added here.\n    This prevents conflicts between identical z-index values in different components.\n*/\n\n/* Toolbox z-index: 40; set in scratch-blocks */\n\n/* tooltips should go over add buttons if they overlap */\n\n/* monitors go over add buttons */\n\n/* \"ask\" block text input goes above monitors */\n\n/* menu-bar should go over monitors, alerts and tutorials */\n\n/* behind menu-bar */\n\n/* Block drag z-index: 1000; default 50 is overriden in blocks.css */\n\n/* so it is draggable into other panes */\n\n/* behind modals */\n\n/* behind modals */\n\n/* in most interfaces, the context menu is always on top */\n\n.drag-layer_drag-layer_3Xec- {\n    position: fixed;\n    pointer-events: none;\n    z-index: 1000;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    direction: ltr;\n}\n\n.drag-layer_image-wrapper_2iRP3 {\n    /* Absolute allows wrapper to snuggly fit image */\n    position: absolute;\n}\n\n.drag-layer_image_1inbS {\n    max-width: 80px;\n    max-height: 80px;\n    min-width: 50px;\n    min-height: 50px;\n\n    /* Center the dragging image on the given position */\n    margin-left: -50%;\n    margin-top: -50%;\n\n    padding: 0.25rem;\n    border: 2px solid var(--looks-secondary);\n    background: var(--ui-white);\n    border-radius: 0.5rem;\n\n    /* Use the same drop shadow as stage dragging */\n    box-shadow: 5px 5px 5px var(--shadow);\n}\n", ""]);

// exports
exports.locals = {
	"drag-layer": "drag-layer_drag-layer_3Xec-",
	"dragLayer": "drag-layer_drag-layer_3Xec-",
	"image-wrapper": "drag-layer_image-wrapper_2iRP3",
	"imageWrapper": "drag-layer_image-wrapper_2iRP3",
	"image": "drag-layer_image_1inbS"
};