exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.load-extension_code_2UsqN,\n.load-extension_unsandboxed-container_3_M9P,\n.load-extension_unsandboxed-warning_2iFhK,\n.load-extension_sandboxed_3ynzH {\n    margin: 8px 0;\n}\n\n.load-extension_unsandboxed-container_3_M9P {\n    display: flex;\n    align-items: center;\n}\n\n.load-extension_unsandboxed-checkbox_1tSmf {\n    margin-right: 8px;\n}\n\n.load-extension_unsandboxed-warning_2iFhK {\n    padding: 0.5rem;\n    border-radius: 0.25rem;\n    background-color: rgba(255, 81, 81, 0.25);\n    border: 1px solid red;\n}\n", ""]);

// exports
exports.locals = {
	"code": "load-extension_code_2UsqN",
	"unsandboxed-container": "load-extension_unsandboxed-container_3_M9P",
	"unsandboxedContainer": "load-extension_unsandboxed-container_3_M9P",
	"unsandboxed-warning": "load-extension_unsandboxed-warning_2iFhK",
	"unsandboxedWarning": "load-extension_unsandboxed-warning_2iFhK",
	"sandboxed": "load-extension_sandboxed_3ynzH",
	"unsandboxed-checkbox": "load-extension_unsandboxed-checkbox_1tSmf",
	"unsandboxedCheckbox": "load-extension_unsandboxed-checkbox_1tSmf"
};