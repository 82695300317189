exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n\n/* layout contants from `layout-constants.js` */\n\n/* overridden by src/lib/themes/guiHelpers.js */\n\n.library_library-scroll-grid_1jyXm {\n    display: flex;\n    justify-content: flex-start;\n    align-content: flex-start;\n    align-items: flex-start;\n    background: var(--ui-secondary);\n    flex-grow: 1;\n    flex-wrap: wrap;\n    overflow-y: auto;\n    height: auto;\n    padding: 0.5rem;\n    height: calc(100% - 3.125rem);\n}\n\n.library_library-scroll-grid_1jyXm.library_withFilterBar_26Opm {\n    height: calc(100% - 3.125rem - 2.5rem - 2rem);\n}\n\n.library_filter-bar_1W0DW {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    align-items: center;\n    height: calc(2.5rem + 2rem); /* padding */\n    background-color: var(--looks-transparent);\n    padding: 0 1rem;\n    font-size: .875rem;\n}\n\n.library_filter-bar-item_99aoX {\n    margin-right: .75rem;\n}\n\n.library_filter_2k-oj {\n    flex-grow: 0;\n}\n\n.library_filter-input_6w2X2 {\n    width: 11.5rem;\n    transition: .2s;\n}\n\n.library_filter-input_6w2X2:focus,\n.library_filter-input_6w2X2:not([value=\"\"]) {\n    width: 18.75rem;\n}\n\n.library_divider_2xD3D {\n    transform: scaleY(1.39);\n    height: 2.5rem;\n}\n\n.library_tag-wrapper_2-QEQ {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    height: 2.5rem;\n    overflow: hidden;\n}\n\n.library_spinner-wrapper_1C8Ew {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n", ""]);

// exports
exports.locals = {
	"library-scroll-grid": "library_library-scroll-grid_1jyXm",
	"libraryScrollGrid": "library_library-scroll-grid_1jyXm",
	"withFilterBar": "library_withFilterBar_26Opm",
	"filter-bar": "library_filter-bar_1W0DW",
	"filterBar": "library_filter-bar_1W0DW",
	"filter-bar-item": "library_filter-bar-item_99aoX",
	"filterBarItem": "library_filter-bar-item_99aoX",
	"filter": "library_filter_2k-oj",
	"filter-input": "library_filter-input_6w2X2",
	"filterInput": "library_filter-input_6w2X2",
	"divider": "library_divider_2xD3D",
	"tag-wrapper": "library_tag-wrapper_2-QEQ",
	"tagWrapper": "library_tag-wrapper_2-QEQ",
	"spinner-wrapper": "library_spinner-wrapper_1C8Ew",
	"spinnerWrapper": "library_spinner-wrapper_1C8Ew"
};