exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.spinner_container_2gp3A {\n    display: flex;\n    width: 100%;\n    height: 100%;\n    align-items: center;\n    justify-content: center;\n}\n\n.spinner_spinner_3_DJ6 {\n    width: 64px;\n    height: 64px;\n    border-radius: 50%;\n    border: 6px solid;\n    border-color: var(--looks-secondary) transparent var(--looks-secondary) transparent;\n    animation: spinner_spinner_3_DJ6 1.2s linear infinite;\n}\n\n@keyframes spinner_spinner_3_DJ6 {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}\n", ""]);

// exports
exports.locals = {
	"container": "spinner_container_2gp3A",
	"spinner": "spinner_spinner_3_DJ6"
};